<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Back Office
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          You are not verified! 🔐
        </h2>
        <p class="mb-2">
          Please wait for verify by admin.
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          @click="verifyRoute()"
        >Go to Dashboard</b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { initialAbility } from '@/libs/acl/config'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink, BImg, BButton, VuexyLogo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    verifyRoute() {
      useJwt.login()
        .then(response => {
          if (response.data.code !== 0) {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

            // Remove userData from localStorage
            localStorage.removeItem('userData')

            // Reset ability
            this.$ability.update(initialAbility)
            return this.$router.push({name: 'auth-login'})
          }
          let userData = response.data.result.profile
          // useJwt.setToken(response.data.result.token)
          // useJwt.setRefreshToken(response.data.refreshToken)
          switch (userData.role) {
            case 'admin':
              userData.ability = [ { "action": "manage", "subject": "all" } ]
              break
            case 'system':
              userData.ability = [ { "action": "read", "subject": "page" }, { "action": "read", "subject": "credit" }, { "action": "read", "subject": "user" } ]
              break
            case 'employee':
              userData.ability = [ { "action": "read", "subject": "page" }, { "action": "read", "subject": "credit" } ]
              break
            default:
              userData.ability = [ { "action": "read", "subject": "Auth" } ]
          }
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)
          const stay = getHomeRouteForLoggedInUser(userData.role)
          if (stay.name == 'access-control') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${userData.displayName || userData.username}, You are not verified!`,
                icon: 'LockIcon',
                variant: 'warning',
                text: `Please wait for verify by admin. soon!`,
              },
            })
            return
          }
          this.$router.replace(stay)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${userData.displayName || userData.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                },
              })
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
